import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="min-h-screen w-full bg-white px-8 py-16 font-mono">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="mx-auto max-w-4xl"
      >
        {/* Header */}
        <div className="desktop:mb-32 laptop:mb-32 tablet:mb-24 mobile:mb-16">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl font-bold tracking-tighter text-black"
          >
            test
          </motion.h1>
        </div>

        {/* Description */}
        <div className="desktop:w-2/3 laptop:w-2/3 tablet:w-full mobile:w-full">
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="desktop:text-xl laptop:text-xl tablet:text-lg mobile:text-base text-gray-600 leading-relaxed"
          >
            test - minimalist black & white design with lots of whitespace,
            monospace font, no images
          </motion.p>
        </div>

        {/* CTA Button */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
          className="mt-12"
        >
          <Link
            to="#"
            className="
              inline-block
              border-2
              border-black
              px-8
              py-4
              text-black
              hover:bg-black
              hover:text-white
              transition-colors
              duration-300
              desktop:text-lg
              laptop:text-lg
              tablet:text-base
              mobile:text-sm
            "
          >
            <div className="flex items-center space-x-2">
              <span>Get Started</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
              </svg>
            </div>
          </Link>
        </motion.div>

        {/* Decorative Element */}
        <motion.div
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ delay: 0.8, duration: 0.8 }}
          className="
            desktop:mt-32
            laptop:mt-32
            tablet:mt-24
            mobile:mt-16
            h-px
            bg-gray-200
          "
        />
      </motion.div>
    </section>
  );
};

export default Hero;
